.terms-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 15px;
    line-height: 1.6;
}

.terms-container h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.terms-container h2 {
    font-size: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
}

.terms-container h3 {
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.terms-container p, ul, li {
    margin: 0.5em 0;
}

.terms-container ul {
    padding-left: 20px;
}

.terms-container a {
    color: #007bff;
    text-decoration: none;
}

.terms-container a:hover {
    text-decoration: underline;
}

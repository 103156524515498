@keyframes logoEntrance {
    0% {
        transform: scale(1.5) translateX(100px);
        opacity: 0;
    }
    100% {
        transform: scale(1) translateX(0);
        opacity: 1;
    }
}

.logo-hidden {
    opacity: 0;
}

.logo-visible {
    animation: logoEntrance 0.5s ease-out forwards;
}

.gm-style-cc {
    display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"] {
    display: none !important;
}

.gmnoprint {
    bottom: 150px !important;
}
